import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {shipmentsActions} from 'state/shipments/slice';
import {DateTime} from 'luxon';
const snapOptions = {includeMetadataChanges: true};

const inactiveStartDate = DateTime.now().minus({months: 2}).startOf('day').toJSDate();
const inactiveEndDate = DateTime.now().plus({months: 6}).endOf('day').toJSDate();

let inboundsActiveUnsubscribe = null;
let inboundsInactiveUnsubscribe = null;
let outboundsActiveUnsubscribe = null;
let outboundsInactiveUnsubscribe = null;

const useShipments = () => {
  const {shipmentsActiveInbound, shipmentsInactiveInbound, shipmentsActiveOutbound, shipmentsInactiveOutbound, touched} = useSelector(state => state.shipments);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(shipmentsActions.setTouched(true));

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(shipmentsActions.forgetState());
        if (inboundsActiveUnsubscribe) inboundsActiveUnsubscribe();
        if (inboundsInactiveUnsubscribe) inboundsInactiveUnsubscribe();
        if (outboundsActiveUnsubscribe) outboundsActiveUnsubscribe();
        if (outboundsInactiveUnsubscribe) outboundsInactiveUnsubscribe();
      }

      // USER IS AUTHENTICATED
      // Query all Inbounds with a Status of "Scheduled" and "In Progress"; All events returned
      // by this query should be displayed to Shipments view (i.e. not filtered by timeframe)
      const inboundsActiveQuery = Firestore.query(
        Firestore.collection('Inbounds'),
        Firestore.where('Status', 'in', ['Scheduled', 'In Progress']),
      );
      const inboundsActiveSnapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Schedule: {
              ...docData.Schedule,
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            ScheduleType: 'Inbound',
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            TimeReceived: docData?.TimeReceived?.toMillis ? docData.TimeReceived.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(shipmentsActions.setShipmentsActiveInbound({data: snapData, pending: false}));
      };
      const inboundsActiveSnapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useShipments inboundsActiveSnapError: ', error.message, {error});
      };
      inboundsActiveUnsubscribe = Firestore.onSnapshot(inboundsActiveQuery, snapOptions, inboundsActiveSnapData, inboundsActiveSnapError);

      // Query all Inbounds with a Status of "Completed" and "Cancelled"; Only events with a TimeCompleted
      // between the previous 1 month and next 6 months should be displayed to Shipments view
      const inboundsInactiveQuery = Firestore.query(
        Firestore.collection('Inbounds'),
        Firestore.where('Status', 'in', ['Completed', 'Cancelled']),
        Firestore.where('TimeReceived', '>', inactiveStartDate),
        Firestore.where('TimeReceived', '<', inactiveEndDate),
      );
      const inboundsInactiveSnapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Schedule: {
              ...docData.Schedule,
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            ScheduleType: 'Inbound',
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            TimeReceived: docData?.TimeReceived?.toMillis ? docData.TimeReceived.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(shipmentsActions.setShipmentsInactiveInbound({data: snapData, pending: false}));
      };
      const inboundInactiveSnapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useShipments inboundInactiveSnapError: ', error.message, {error});
      };
      inboundsInactiveUnsubscribe = Firestore.onSnapshot(inboundsInactiveQuery, snapOptions, inboundsInactiveSnapData, inboundInactiveSnapError);

      // Query all Shipments-Outbound with a Status of "Scheduled" and "In Progress"; All events returned
      // by this query should be displayed to Shipments view (i.e. not filtered by timeframe)
      const outboundsActiveQuery = Firestore.query(
        Firestore.collection('Outbounds'),
        Firestore.where('Status', 'in', ['Scheduled', 'In Progress']),
      );
      const outboundsActiveSnapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            ScheduleType: 'Outbound',
            Schedule: {
              ...docData.Schedule,
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            TimeStarted: docData?.TimeStarted?.toMillis ? docData.TimeStarted.toMillis() : null,
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(shipmentsActions.setShipmentsActiveOutbound({data: snapData, pending: false}));
      };
      const outboundsActiveSnapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useShipments outboundsActiveSnapError: ', error.message, {error});
      };
      outboundsActiveUnsubscribe = Firestore.onSnapshot(outboundsActiveQuery, snapOptions, outboundsActiveSnapData, outboundsActiveSnapError);

      // Query all Outbounds with a Status of "Completed" and "Cancelled"; Only events with a StartTime
      // between the previous 1 month and next 6 months should be displayed to Shipments view
      const outboundsInactiveQuery = Firestore.query(
        Firestore.collection('Outbounds'),
        Firestore.where('Status', 'in', ['Completed', 'Cancelled']),
        Firestore.where('TimeStarted', '>', inactiveStartDate),
        Firestore.where('TimeStarted', '<', inactiveEndDate),
      );
      const outboundsInactiveSnapData = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Accounting: (() => {
              if (!docData?.Accounting) return null;
              return {
                ...docData.Accounting,
                EnteredOn: docData.Accounting.EnteredOn.toMillis(),
              };
            })(),
            Schedule: {
              ...docData.Schedule,
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            ScheduleType: 'Outbound',
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            TimeStarted: docData?.TimeStarted?.toMillis ? docData.TimeStarted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(shipmentsActions.setShipmentsInactiveOutbound({data: snapData, pending: false}));
      };
      const outboundsInactiveSnapError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useShipments inboundInactiveSnapError: ', error.message, {error});
      };
      outboundsInactiveUnsubscribe = Firestore.onSnapshot(outboundsInactiveQuery, snapOptions, outboundsInactiveSnapData, outboundsInactiveSnapError);
    });
  }, [dispatch, touched]);

  return {
    shipmentsActiveInbound,
    shipmentsInactiveInbound,
    shipmentsActiveOutbound,
    shipmentsInactiveOutbound,
    shipmentEvents: [
      ...shipmentsActiveInbound.data,
      ...shipmentsInactiveInbound.data,
      ...shipmentsActiveOutbound.data,
      ...shipmentsInactiveOutbound.data,
    ],
    pending: Boolean(
      shipmentsActiveInbound.pending ||
      shipmentsInactiveInbound.pending ||
      shipmentsActiveOutbound.pending ||
      shipmentsInactiveOutbound.pending
    ),
    touched,
  };
};

export default useShipments;
