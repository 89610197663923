import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Auth, Firestore} from 'firebaseConfig';
import {accountingActions} from 'state/accounting/slice';
import {DateTime} from 'luxon';
const snapOptions = {includeMetadataChanges: true};
const recentDate = DateTime.now().minus({days: 30}).startOf('day').toJSDate();

let inShipDocsPendingUnsubscribe = null;
let inShipDocsRecentUnsubscribe = null;
let addMatlFormsPendingUnsubscribe = null;
let addMatlFormsRecentUnsubscribe = null;
let processFormsPendingUnsubscribe = null;
let processFormsRecentUnsubscribe = null;
let outboundFormsPendingUnsubscribe = null;
let outboundFormsRecentUnsubscribe = null;

const useAccounting = () => {
  const {touched} = useSelector(state => state.accounting);
  const {inShippingDocsPending, inShippingDocsRecent} = useSelector(state => state.accounting);
  const {addMaterialFormsPending, addMaterialFormsRecent} = useSelector(state => state.accounting);
  const {processFormsPending, processFormsRecent} = useSelector(state => state.accounting);
  const {outboundFormsPending} = useSelector(state => state.accounting);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only run remainder of code if hook untouched; Prevents duplicate invocations;
    if (touched) return;
    dispatch(accountingActions.setTouched(true));

    // Make sure user is authenticated before invoking listener
    Auth.onAuthStateChanged(user => {
      // USER NOT AUTHENTICATED
      // When user is no longer authenticate, clear redux state and unsubscribe to listener
      if (!user) {
        dispatch(accountingActions.forgetState());
        if (inShipDocsPendingUnsubscribe) inShipDocsPendingUnsubscribe();
        if (inShipDocsRecentUnsubscribe) inShipDocsRecentUnsubscribe();
        if (addMatlFormsPendingUnsubscribe) addMatlFormsPendingUnsubscribe();
        if (addMatlFormsRecentUnsubscribe) addMatlFormsRecentUnsubscribe();
        if (processFormsPendingUnsubscribe) processFormsPendingUnsubscribe();
        if (processFormsRecentUnsubscribe) processFormsRecentUnsubscribe();
        if (outboundFormsPendingUnsubscribe) outboundFormsPendingUnsubscribe();
        if (outboundFormsRecentUnsubscribe) outboundFormsRecentUnsubscribe();
        return;
      }

      // INBOUND SHIPPING DOCUMENTS PENDING
      // Query Inbound-Shipping-Docs and return all documents that still need to be billed
      const inShipDocsPendingQuery = Firestore.query(
        Firestore.collection('Inbound-Shipping-Docs'),
        Firestore.where('Accounting.DateInvoiced', '==', null),
      );
      const inShipDocsPendingSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            '@Inbounds': {
              ...docData['@Inbounds'],
              TimeReceived: docData['@Inbounds'].TimeReceived.toMillis(),
            },
            'Accounting': {
              ...docData.Accounting,
              DateInvoiceable: docData?.Accounting?.DateInvoiceable?.toMillis ? docData.Accounting.DateInvoiceable.toMillis() : null,
              DateInvoiced: docData?.Accounting?.DateInvoiced?.toMillis ? docData.Accounting.DateInvoiced.toMillis() : null,
            },
            'Notice': (() => {
              if (!docData?.Notice) return null;
              return {
                ...docData.Notice,
                EnteredOn: docData.Notice.EnteredOn.toMillis(),
              };
            })(),
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setInShippingDocsPending({data: snapData, pending: false}));
      };
      const inShipDocsPendingError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting inShipDocsError: ', error.message, {error});
      };
      inShipDocsPendingUnsubscribe = Firestore.onSnapshot(inShipDocsPendingQuery, snapOptions, inShipDocsPendingSnap, inShipDocsPendingError);

      // INBOUND SHIPPING DOCUMENTS PENDING
      // Query Inbound-Shipping-Docs and return all documents with Accounting.DateInvoiced within last 30 days
      const inShipDocsRecentQuery = Firestore.query(
        Firestore.collection('Inbound-Shipping-Docs'),
        Firestore.where('Accounting.DateInvoiced', '>=', DateTime.now().minus({days: 30}).startOf('day').toJSDate()),
        Firestore.where('Accounting.DateInvoiced', '<=', DateTime.now().endOf('day').toJSDate()),
      );
      const inShipDocsRecentSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            '@Inbounds': {
              ...docData['@Inbounds'],
              TimeReceived: docData['@Inbounds'].TimeReceived.toMillis(),
            },
            'Accounting': {
              ...docData.Accounting,
              DateInvoiceable: docData?.Accounting?.DateInvoiceable?.toMillis ? docData.Accounting.DateInvoiceable.toMillis() : null,
              DateInvoiced: docData?.Accounting?.DateInvoiced?.toMillis ? docData.Accounting.DateInvoiced.toMillis() : null,
            },
            'Notice': (() => {
              if (!docData?.Notice) return null;
              return {
                ...docData.Notice,
                EnteredOn: docData.Notice.EnteredOn.toMillis(),
              };
            })(),
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setInShippingDocsRecent({data: snapData, pending: false}));
      };
      const inShipDocsRecentError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting inShipDocsError: ', error.message, {error});
      };
      inShipDocsRecentUnsubscribe = Firestore.onSnapshot(inShipDocsRecentQuery, snapOptions, inShipDocsRecentSnap, inShipDocsRecentError);

      // PRODUCTION ADD MATERIAL FORMS PENDING
      // Query Production-Add-Material and return all documents that Status == Completed and Accounting == null
      const addMatlFormsQuery = Firestore.query(
        Firestore.collection('Production-Add-Material'),
        Firestore.where('Status', '==', 'Completed'),
        Firestore.where('Accounting', '==', null),
      );
      const addMatlFormsSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Accounting: (() => {
              if (!snapData?.Accounting) return null;
              return {
                ...snapData.Accounting,
                EnteredOn: snapData?.Accounting?.EnteredOn ? snapData.Accounting.EnteredOn.toMillis() : null,
              };
            })(),
            TimeCompleted: docData.TimeCompleted.toMillis(),
            TimeCreated: docData.TimeCreated.toMillis(),
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setAddMaterialFormsPending({data: snapData, pending: false}));
      };
      const addMatlFormsError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting addMatlFormsError: ', error.message, {error});
      };
      addMatlFormsPendingUnsubscribe = Firestore.onSnapshot(addMatlFormsQuery, snapOptions, addMatlFormsSnap, addMatlFormsError);

      // PRODUCTION ADD MATERIAL FORMS RECENT
      // Query Production-Add-Material and return all documents with Accounting.EnteredOn date within last 30 days
      const addMatlFormsRecentQuery = Firestore.query(
        Firestore.collection('Production-Add-Material'),
        Firestore.where('Accounting.EnteredOn', '>', recentDate),
      );
      const addMatlFormsRecentSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();

          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            'Accounting': (() => {
              if (!docData?.Accounting) return null;
              return {
                ...docData.Accounting,
                EnteredOn: docData?.Accounting?.EnteredOn ? docData.Accounting.EnteredOn.toMillis() : null,
              };
            })(),
            'TimeCompleted': docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            'TimeCreated': docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setAddMaterialFormsRecent({data: snapData, pending: false}));
      };
      const addMatlFormsRecentError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting addMatlFormsRecentError: ', error.message, {error});
      };
      addMatlFormsRecentUnsubscribe = Firestore.onSnapshot(addMatlFormsRecentQuery, snapOptions, addMatlFormsRecentSnap, addMatlFormsRecentError);

      // PRODUCTION PRODUCTION FORMS PENDING
      // Query Production-Process and return all documents that Status == Completed and Accounting == null
      const processFormsQuery = Firestore.query(
        Firestore.collection('Production-Process'),
        Firestore.where('Status', '==', 'Completed'),
        Firestore.where('Accounting', '==', null),
      );
      const processFormsSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Accounting: (() => {
              if (!snapData?.Accounting) return null;
              return {
                ...snapData.Accounting,
                EnteredOn: snapData?.Accounting?.EnteredOn ? snapData.Accounting.EnteredOn.toMillis() : null,
              };
            })(),
            TimeCompleted: docData.TimeCompleted.toMillis(),
            TimeCreated: docData.TimeCreated.toMillis(),
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setProcessFormsPending({data: snapData, pending: false}));
      };
      const processFormsError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting processFormsError: ', error.message, {error});
      };
      processFormsPendingUnsubscribe = Firestore.onSnapshot(processFormsQuery, snapOptions, processFormsSnap, processFormsError);

      // PRODUCTION PRODUCTION FORMS RECENT
      // Query Production-Process and return all documents with Accounting.EnteredOn date within last 30 days
      const processFormsRecentQuery = Firestore.query(
        Firestore.collection('Production-Process'),
        Firestore.where('Accounting.EnteredOn', '>', recentDate),
      );
      const processFormsRecentSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            'DocId': doc.id,
            'Accounting': (() => {
              if (!docData?.Accounting) return null;
              return {
                ...docData.Accounting,
                EnteredOn: docData?.Accounting?.EnteredOn ? docData.Accounting.EnteredOn.toMillis() : null,
              };
            })(),
            'TimeCompleted': docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            'TimeCreated': docData?.TimeCreated?.toMillis ? docData.TimeCreated.toMillis() : null,
            'System': {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setProcessFormsRecent({data: snapData, pending: false}));
      };
      const processFormsRecentError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting processFormsRecentError: ', error.message, {error});
      };
      processFormsRecentUnsubscribe = Firestore.onSnapshot(processFormsRecentQuery, snapOptions, processFormsRecentSnap, processFormsRecentError);

      // OUTBOUND FORMS PENDING
      // Query Outbounds and return all documents that Status == Completed and Accounting == null
      const outboundFormsPendingQuery = Firestore.query(
        Firestore.collection('Outbounds'),
        Firestore.where('Status', '==', 'Completed'),
        Firestore.where('Accounting', '==', null),
      );
      const outboundFormsPendingSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Schedule: {
              ...docData?.Schedule || {},
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            TimeStarted: docData?.TimeStarted?.toMillis ? docData.TimeStarted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setOutboundFormsPending({data: snapData, pending: false}));
      };
      const outboundFormsPendingError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting outboundFormsPendingError: ', error.message, {error});
      };
      outboundFormsPendingUnsubscribe = Firestore.onSnapshot(outboundFormsPendingQuery, snapOptions, outboundFormsPendingSnap, outboundFormsPendingError);

      // OUTBOUND FORMS RECENT
      // Query Outbounds and return all documents with Accounting.EnteredOn date within last 30 days
      const outboundFormsRecentQuery = Firestore.query(
        Firestore.collection('Outbounds'),
        Firestore.where('Accounting.EnteredOn', '>', recentDate),
      );
      const outboundFormsRecentSnap = snap => {
        const {fromCache, hasPendingWrites} = snap?.metadata || {};
        if (fromCache || hasPendingWrites) return;

        const snapData = [];
        snap.forEach(doc => {
          const docData = doc.data();
          const cleanDoc = {
            ...docData,
            DocId: doc.id,
            Accounting: (() => {
              if (!docData?.Accounting) return null;
              return {
                ...docData.Accounting,
                EnteredOn: docData?.Accounting?.EnteredOn ? docData.Accounting.EnteredOn.toMillis() : null,
              };
            })(),
            Schedule: {
              EstDuration: docData?.Schedule?.EstDuration || null,
              Time: docData?.Schedule?.Time?.toMillis ? docData.Schedule.Time.toMillis() : null,
            },
            TimeCompleted: docData?.TimeCompleted?.toMillis ? docData.TimeCompleted.toMillis() : null,
            TimeStarted: docData?.TimeStarted?.toMillis ? docData.TimeStarted.toMillis() : null,
            System: {
              ...docData.System,
              EnteredOn: docData?.System?.EnteredOn?.toMillis ? docData.System.EnteredOn.toMillis() : null,
            },
          };
          snapData.push(cleanDoc);
        });
        dispatch(accountingActions.setOutboundFormsRecent({data: snapData, pending: false}));
      };
      const outboundFormsRecentError = error => {
        if (error.message !== 'Missing or insufficient permissions.') alert(error.message);
        console.log('useAccounting outboundFormsRecentError: ', error.message, {error});
      };
      outboundFormsRecentUnsubscribe = Firestore.onSnapshot(outboundFormsRecentQuery, snapOptions, outboundFormsRecentSnap, outboundFormsRecentError);
    });
  }, [dispatch, touched]);

  return {
    addMaterialFormsPending: addMaterialFormsPending.data,
    addMaterialFormsRecent: addMaterialFormsRecent.data,
    inShippingDocsPending: inShippingDocsPending.data,
    inShippingDocsRecent: inShippingDocsRecent.data,
    processFormsPending: processFormsPending.data,
    processFormsRecent: processFormsRecent.data,
    pending: Boolean(
      inShippingDocsPending.pending ||
      inShippingDocsRecent.pending ||
      addMaterialFormsPending.pending ||
      addMaterialFormsRecent.pending ||
      processFormsPending.pending ||
      processFormsRecent.pending ||
      outboundFormsPending.pending
    ),
    touched,
  };
};

export default useAccounting;
