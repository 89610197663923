// An array of roles that should be granted access to portal based on user's roleBatteries custom claim
export const authorizedRoles = [
  'Admin',
  'Manager',
  'Accounting User',
  'Accounting Manager',
  'Production User',
  'Production Manager',
  'Sales User',
  'Sales Manager',
  'Tracking User',
  'Tracking Manager',
];

// States and provinces for United States, Canada and Mexico
export const usStates = [
  {value: 'AK'},
  {value: 'AL'},
  {value: 'AR'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DC'},
  {value: 'DE'},
  {value: 'FL'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'IA'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'MA'},
  {value: 'MD'},
  {value: 'ME'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MO'},
  {value: 'MS'},
  {value: 'MT'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'NE'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NV'},
  {value: 'NY'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VA'},
  {value: 'VI'},
  {value: 'VT'},
  {value: 'WA'},
  {value: 'WI'},
  {value: 'WV'},
  {value: 'WY'},
];
export const canadaProvinces = [
  {value: 'AB'},
  {value: 'BC'},
  {value: 'MB'},
  {value: 'NB'},
  {value: 'NL'},
  {value: 'NT'},
  {value: 'NS'},
  {value: 'NU'},
  {value: 'ON'},
  {value: 'PE'},
  {value: 'QC'},
  {value: 'SK'},
  {value: 'YT'},
];
export const mexicanStates = [
  {value: 'AG'},
  {value: 'BN'},
  {value: 'BS'},
  {value: 'CH'},
  {value: 'CI'},
  {value: 'CL'},
  {value: 'CP'},
  {value: 'CS'},
  {value: 'DF'},
  {value: 'DG'},
  {value: 'GE'},
  {value: 'GJ'},
  {value: 'HD'},
  {value: 'JA'},
  {value: 'MC'},
  {value: 'MR'},
  {value: 'MX'},
  {value: 'NA'},
  {value: 'NL'},
  {value: 'OA'},
  {value: 'PU'},
  {value: 'QE'},
  {value: 'QI'},
  {value: 'SI'},
  {value: 'SL'},
  {value: 'SO'},
  {value: 'TA'},
  {value: 'TB'},
  {value: 'TL'},
  {value: 'VC'},
  {value: 'YU'},
  {value: 'ZA'},
];

// Format numbers to US Currency
export const usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
export const usdFormatterAcct = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', currencySign: 'accounting'});
export const usdFormatterDec3 = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 3});

// Common Regex variables
export const postalRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/im;
export const mexicoPostalRegex = /[0-9]{5}/g;
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line no-useless-escape

export const billTypes = [
  'Charge',
  'Market',
  'No Charge',
  'Payment',
];

export const inboundNotices = [
  {label: 'Improper insulation or prevention of short circuit.', value: 'Insulation'},
  {label: 'Improper or inadequate material bracing.', value: 'Bracing'},
  {label: 'Improper or missing markings/labels.', value: 'Markings'},
  {label: 'Improper packaging or poor container condition.', value: 'Packaging'},
  {label: 'Improper shipping description or material identification.', value: 'Identification'},
  {label: 'Leaking batteries or cells.', value: 'Leaking'},
  {label: 'Missing battery caps.', value: 'Missing Caps'},
  {label: 'Structurally compromised or damaged pallet.', value: 'Damaged Pallet'},
];

export const handlingCodes = [
  {value: 'H010', label: 'H010-Metals recovery'},
  {value: 'H039', label: 'H039-Other recovery or reclamation for reuse'},
  {value: 'H070', label: 'H070-Chemical treatment'},
  {value: 'H132', label: 'H132-Landfill'},
  {value: 'H141', label: 'H141-Storage and transfer'},
  {value: 'S010', label: 'S010-Stored and transferred for metals recovery'},
];

// First item in each array is the container code; Second index is the container code types;
export const containerList = [
  ['BA', []],
  ['CF', ['Box', 'Crate', 'Gaylord', 'Tote']],
  ['CM', []],
  ['CW', ['Crate', 'Pallet']],
  ['CY', []],
  ['DF', ['2', '5', '10', '14', '15', '20', '30', '55', '85']],
  ['DM', ['2', '5', '10', '14', '15', '20', '30', '55', '85']],
  ['DT', []],
  ['DW', []],
  ['LO', ['Loose']],
];
export const containerCodes = containerList.reduce((acc, cV) => {
  return [...acc, cV[0]];
}, []);
export const constainerTypesMap = containerList.reduce((acc, cV) => {
  return {...acc, [cV[0]]: cV[1]};
}, {});
export const containerOptions = (() => {
  const options = [];

  containerList.forEach(listItem => {
    if (listItem[1].length === 0) {
      const cleanOption = {Merge: listItem[0], Code: listItem[0], Type: ''};
      options.push(cleanOption);
    }
    else {
      listItem[1].forEach(containerType => {
        const cleanOption = {Merge: `${listItem[0]}, ${containerType}`, Code: listItem[0], Type: containerType};
        options.push(cleanOption);
      });
    }
  });

  return options;
})();
