import {FormButton, FormikForm, SubmitButton} from '@kbi/story-forms';
import {Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import {useUiModal} from 'state';

import FacilityLocationField from './FacilityLocationField';
import FacilityUnitField from './FacilityUnitField';
import ContainersField from './ContainersField';
import useFormProps from './useFormProps';

const ProductionMovementModal = () => {
  const {clearModal, defaultDialog, modal} = useUiModal();
  const formProps = useFormProps();

  return (
    <Dialog {...defaultDialog} open={modal.open && modal.target === 'ProductionMovementModal'}>
      <FormikForm {...formProps}>
        {({dirty, resetForm, status}) => (<>
          <DialogTitle>Move Containers</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <FacilityLocationField />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FacilityUnitField />
              </Grid>
              <Grid item xs={12}>
                <ContainersField />
              </Grid>
              {!!status?.text && (<Grid item xs={12}>
                <Alert severity={status?.severity || 'info'} style={{margin: '8px 0px'}}>
                  {status.text}
                </Alert>
              </Grid>)}
            </Grid>
          </DialogContent>
          <DialogActions style={{justifyContent: 'space-between'}}>
            <FormButton onClick={() => clearModal({resetForm})} color='secondary' variant='text'>Cancel</FormButton>
            <SubmitButton color='primary' variant='text' disabled={!dirty}>Submit</SubmitButton>
          </DialogActions>
        </>)}
      </FormikForm>
    </Dialog>
  );
};

export default ProductionMovementModal;
